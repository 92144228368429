<template>
    <div>

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0"
        >
            <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="fetchUsers"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                empty-text="No matching records found"
            >

                <template #cell(profile)="data">
                    {{ data.item.profile.email }}
                </template>

                <template #cell(products)="data">
                    {{ data.item.products.length }} productos
                </template>

                <template #cell(total)="data">
                    $ {{ numberFormat(data.item.total) }}
                </template>

                <template #cell(createdAt)="data">
                   {{ dateFormat(data.item.createdAt) }}
                </template>

                <template #cell(status)="data">
                    {{ getStatusLabel(data.item.status) }}
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-button-group>
                        <b-button variant="primary" @click="editUser(data.item)">
                            <feather-icon
                                icon="EyeIcon"
                                size="16"
                                class="align-middle"
                            />
                        </b-button>
                        <!--                        <b-button variant="danger" @click="deleteUser(data.item)">
                                                    <feather-icon
                                                        icon="Trash2Icon"
                                                        size="16"
                                                        class="align-middle"
                                                    />
                                                </b-button>-->
                    </b-button-group>
                </template>

            </b-table>
        </b-card>
    </div>
</template>

<script>
import {BButton, BButtonGroup, BCard, BTable} from "bootstrap-vue"
import store from "@/store"
import {onUnmounted, ref} from "@vue/composition-api"
import useOrdersList from "@/views/orders/useOrdersList"
import orderStoreModule from "@/views/orders/orderStoreModule"

export default {
    name: "OrdersView",
    components: {
        BCard,
        BTable,
        BButton,
        BButtonGroup
    },
    methods: {
        numberFormat(number) {
            number = parseFloat(number)
            let val = (number / 1).toFixed(2)//.replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        dateFormat(date) {
            return new Date(date.toDate()).toLocaleDateString()
        },
        getStatusLabel(status) {
            switch (status) {
                case 0:
                    return 'Cancelado'
                case 1:
                    return 'Pendiente de pago'
                case 2:
                    return 'Confirmado'
            }
        },
        addNew() {
            this.userSelected = {}
            this.addNewUser = true
            this.isAddNewUserSidebarActive = true
        },
        editUser(user) {
            this.$router.push('admin-orders/' + user.id)
        },
        deleteUser() {

            this.$swal({
                title: '¿Seguro que desea eliminar el usuario?',
                text: "Ya no podrá acceder al sistema",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    // console.log(result.value, user)
                }
            })
        }
    },
    setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-orders'

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, orderStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const isAddNewUserSidebarActive = ref(false)

        const {
            fetchUsers,
            tableColumns,
            refUserListTable,
            refetchData,
        } = useOrdersList()

        const addNewUser = ref(true)
        const userSelected = ref({})

        return {

            // Sidebar
            isAddNewUserSidebarActive,

            fetchUsers,
            tableColumns,
            refUserListTable,
            refetchData,
            addNewUser,
            userSelected
        }
    }
}
</script>

<style scoped>

</style>
