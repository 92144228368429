import {ref} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useOrdersList() {
    //Use toast
    const toast = useToast()

    const refUserListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        //{key: 'id'},
        //{key: 'first_name', label: 'Nombre'},
        {key: 'profile', label: 'Cliente'},
        {key: 'products', label: 'Cantidad de productos'},
        {key: 'total', label: 'Total'},
        {key: 'createdAt', label: 'Fecha'},
        {key: 'status', label: 'Estado'},
        {key: 'actions', label: 'Acciones'},
    ]

    const refetchData = () => {
        refUserListTable.value.refresh()
    }

    const fetchUsers = (ctx, callback) => {


        store
            .dispatch('app-orders/fetchOrders')
            .then(response => {
                callback(response)
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener lista de ordenes',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }


    return {
        fetchUsers,
        tableColumns,
        refUserListTable,
        refetchData,
    }
}
